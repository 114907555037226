import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { AutoColumn } from "components/Column";
import { stack as StackMenu } from "react-burger-menu";
import { useDarkModeManager } from "state/user/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBullseye,
  faMap,
  faHandHoldingDollar,
  faMoneyBillTransfer,
  faShareFromSquare,
  /* faVault, */
  faLandmark,
  faBook,
  faUserPlus,
  /* faPeopleGroup, */
  faRocket,
  faHandshake,
  faCreditCard,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "assets/images/spacedex-logo.png";
import LogoDark from "assets/images/spacedex-dark-logo.png";
import FalconDark from "assets/images/falcon-black-logo.png";
import FalconWhite from "assets/images/falcon-white-logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

const ButtonLink = styled.a`
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

const ButtonExternalLink = styled(Link)`
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

export default function Menu() {
  const [isDark] = useDarkModeManager();

  let styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      top: "1.2rem",
    },
    bmBurgerBars: {
      background: isDark ? "#2172E5" : "#93BBF2",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      marginTop: "4px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      top: 0,
      left: 0,
    },
    bmMenu: {
      background: isDark ? "#0C162D" : "#FFF",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: isDark ? "#FFFFFF" : "#000000",
      padding: "0.8em",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      top: 0,
    },
  };

  const [open, setOpen] = useState(false);
  const handleSetOpen = () => {
    setOpen(true);
  };
  const handleSetClose = () => {
    setOpen(false);
  };

  return (
    <StackMenu
      styles={styles}
      isOpen={open}
      onOpen={handleSetOpen}
      onClose={handleSetClose}
    >
      <img src={isDark ? Logo : LogoDark} alt="spacedex-logo" width={150} />
      <Container>
        <AutoColumn gap="1rem">
          <Row>
            <ButtonLink
              id={`buy-nav-link`}
              href="https://pasarela.cryptopocket.io/?token=SuwoDKS40KoP35DPkiF4WZ1ZI"
              onClick={handleSetClose}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Row>
                <FontAwesomeIcon icon={faCreditCard} width="24px" />
                <Trans>Buy with Fiat</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink id={`home-nav-link`} href="/" onClick={handleSetClose}>
              <Row>
                <FontAwesomeIcon icon={faHome} width="24px" />
                <Trans>Home</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`copytrading-nav-link`}
              href="/#copytrading"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faFileInvoiceDollar} width="24px" />
                Copy Trading
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`ecosystem-nav-link`}
              href="/#ecosystem"
              onClick={handleSetClose}
            >
              <Row>
                <img
                  src={isDark ? FalconWhite : FalconDark}
                  alt="falcon-logo"
                  width={24}
                />
                <Trans>Ecosystem</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`referral-nav-link`}
              href="/#referral"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faUserPlus} width="24px" />
                <Trans>Referral</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`liquidity-nav-link`}
              href="/#liquidity"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faLandmark} width="24px" />
                <Trans>Liquidity</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`mission-nav-link`}
              href="/#mission"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faBullseye} width="24px" />
                <Trans>Mission and Vision</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`roadmap-nav-link`}
              href="/#roadmap"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faMap} width="24px" />
                RoadMap
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`tokenomics-nav-link`}
              href="/#tokenomics"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faHandHoldingDollar} width="24px" />
                Tokenomics
              </Row>
            </ButtonLink>
          </Row>
          {/* <Row>
            <ButtonLink
              id={`staking-nav-link`}
              href="/#staking"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faVault} width="24px" />
                Staking
              </Row>
            </ButtonLink>
          </Row> */}
          <Row>
            <ButtonLink
              id={`trading-nav-link`}
              href="/#trading"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faMoneyBillTransfer} width="24px" />
                Trading
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`social-nav-link`}
              href="/#social"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faShareFromSquare} width="24px" />
                <Trans>Social</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`spacetools-nav-link`}
              href="/#spacetools"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faRocket} width="24px" />
                <Trans>SpaceTools</Trans>
              </Row>
            </ButtonLink>
          </Row>
          {/* <Row>
            <ButtonLink
              id={`team-nav-link`}
              href="/#team"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faPeopleGroup} width="24px" />
                <Trans>Team</Trans>
              </Row>
            </ButtonLink>
          </Row> */}
          <Row>
            <ButtonLink
              id={`partnership-nav-link`}
              href="/#partnership"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faHandshake} width="24px" />
                <Trans>Partnership</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonExternalLink
              as={Link}
              id={`privacy-nav-link`}
              to="/privacy"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faBook} width="24px" />
                <Trans>Privacy</Trans>
              </Row>
            </ButtonExternalLink>
          </Row>
        </AutoColumn>
      </Container>
    </StackMenu>
  );
}
