import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { isMobile } from "utils/userAgent";
import { MouseoverTooltip } from "components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  margin-top: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

const Table = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 16px;
  margin-top: 1rem;
`;

export function Tokenomics() {
  const currentTimestamp = () => new Date().getTime();
  let startTrading = 1670180406000;

  const sellTax = () => {
    if (startTrading + 31556926000 < currentTimestamp()) {
      return 5;
    }
    if (startTrading + 7889229000 < currentTimestamp()) {
      return 7;
    }
    return 10;
  };

  return (
    <Container id={"tokenomics"}>
      <ThemedText.Title
        color="primary5"
        mb="1rem"
        textAlign={isMobile ? "center" : "left"}
      >
        <Trans>Tokenomics</Trans>
      </ThemedText.Title>
      <Slide left>
        <Table>
          <ThemedText.Body textAlign="center" fontSize={isMobile ? 14 : 16}>
            <Trans>Buy Tax</Trans>
          </ThemedText.Body>
          <Row>
            <ThemedText.Body textAlign="center" fontSize={isMobile ? 14 : 16}>
              <Trans>Sell Tax</Trans>
            </ThemedText.Body>
            <MouseoverTooltip
              text={
                <AutoColumn gap="0.2rem">
                  <RowBetween>
                    <ThemedText.Body>
                      <Trans>Initial</Trans>:
                    </ThemedText.Body>
                    <ThemedText.Body>10%</ThemedText.Body>
                  </RowBetween>
                  <RowBetween>
                    <ThemedText.Body>
                      <Trans>Next 3 Months</Trans>:
                    </ThemedText.Body>
                    <ThemedText.Body>7%</ThemedText.Body>
                  </RowBetween>
                  <RowBetween>
                    <ThemedText.Body>
                      <Trans>Next 9 Months</Trans>:
                    </ThemedText.Body>
                    <ThemedText.Body>5%</ThemedText.Body>
                  </RowBetween>
                  <RowBetween>
                    <ThemedText.Body>Final:</ThemedText.Body>
                    <ThemedText.Body>5%</ThemedText.Body>
                  </RowBetween>
                </AutoColumn>
              }
            >
              <FontAwesomeIcon icon={faCircleQuestion} />
            </MouseoverTooltip>
          </Row>
          <ThemedText.Body textAlign="center" fontSize={isMobile ? 14 : 16}>
            <Trans>Transfer Tax</Trans>
          </ThemedText.Body>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            0%
          </ThemedText.LargeHeader>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            {sellTax()}%
          </ThemedText.LargeHeader>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            0%
          </ThemedText.LargeHeader>
          <ThemedText.Body
            mt="1rem"
            textAlign="center"
            fontSize={isMobile ? 14 : 16}
          >
            <Trans>Stake Falcon</Trans>
          </ThemedText.Body>
          <ThemedText.Body
            mt="1rem"
            textAlign="center"
            fontSize={isMobile ? 14 : 16}
          >
            FalconLP
          </ThemedText.Body>
          <ThemedText.Body
            mt="1rem"
            textAlign="center"
            fontSize={isMobile ? 14 : 16}
          >
            <Trans>Marketing</Trans>
          </ThemedText.Body>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            20%
          </ThemedText.LargeHeader>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            40%
          </ThemedText.LargeHeader>
          <ThemedText.LargeHeader textAlign="center" color="#e24235">
            40%
          </ThemedText.LargeHeader>
        </Table>
      </Slide>
    </Container>
  );
}
