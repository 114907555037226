import Footer from "components/Footer";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components/macro";
import Header from "../components/Header";

import { Home } from "./Home";
import { Privacy } from "./Privacy";
import ParticlesBackground from "components/Particles";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;
  color: #fff;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`;

const FooterWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  bottom: 0;
  z-index: 2;
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

export default function App() {
  return (
    <AppWrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BodyWrapper>
        <Routes>
          <Route index element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
        <Marginer />
      </BodyWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
      <ParticlesBackground />
    </AppWrapper>
  );
}
