import { Trans } from "@lingui/macro";
import Slide, { Roll } from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import TradingImg from "assets/images/home/trading.png";
import Taxes from "assets/images/home/taxes.png";
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const SectionLeft = styled.div`
  border-left: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const SectionRight = styled.div`
  border-right: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

export function Trading() {
  return (
    <Container id={"trading"}>
      <Row>
        <Slide left>
          <AutoColumn gap="1rem">
            <ThemedText.Title
              color="primary5"
              textAlign={isMobile ? "center" : "left"}
            >
              <Trans>Trading</Trans>
            </ThemedText.Title>
            <ThemedText.MediumHeader color="yellow3">
              <Trans>System</Trans>
            </ThemedText.MediumHeader>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  SpaceDex offers a new way to trade without having to sync
                  personal information with the platform. Users can enjoy
                  complete anonymity without needing an email address or
                  password
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  Liquidations are a major risk in trading, but SpaceDex
                  eliminates this risk through the use of an aggregate price
                  feed that guarantees all trades go through smoothly
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
          </AutoColumn>
        </Slide>
        <Roll right>
          <AutoColumn>
            <img src={TradingImg} alt="trading-img" width={256} />
          </AutoColumn>
        </Roll>
      </Row>
      <Row>
        {!isMobile && (
          <Roll left>
            <AutoColumn>
              <img src={Taxes} alt="taxes-icon" width={256} />
            </AutoColumn>
          </Roll>
        )}
        <Slide Right>
          <AutoColumn gap="1rem">
            <ThemedText.MediumHeader textAlign="right" color="yellow3">
              <Trans>Taxes</Trans>
            </ThemedText.MediumHeader>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  We aim to ensure that every penny spent by our investors is
                  received by them in full
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  However, there will be a selling fee of 10% for the first
                  three months and 7% for the next calendar months, followed by
                  a fixed 5% fee for life
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  These fees ensure that we have sufficient funds to maintain
                  our ecosystem and properly allocate funds for marketing
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
          </AutoColumn>
        </Slide>
        {isMobile && (
          <Roll left>
            <AutoColumn>
              <img src={Taxes} alt="taxes-icon" width={256} />
            </AutoColumn>
          </Roll>
        )}
      </Row>
    </Container>
  );
}
