import { Trans } from "@lingui/macro";
import { useDarkModeManager } from "state/user/hooks";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import Logo from "assets/images/falcon-white-logo.png";
import LogoDark from "assets/images/falcon-black-logo.png";
import { RowCenter } from "components/Row";
import { useActiveLocale } from "hooks/useActiveLocale";

const FooterFrame = styled.div`
  width: 100%;
  padding: 1rem;
  z-index: 21;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: 0 -100%;
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px theme.bg2;
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    gap: 0.8rem;
    justify-content: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display
    justify-content: center;
    gap: 0.8rem;
  `};
`;

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

export default function Footer() {
  const [isDark] = useDarkModeManager();
  const currentLanguage = useActiveLocale();
  return (
    <FooterFrame>
      <RowBetween>
        <Row>
          <ExternalLink
            href="https://www.youtube.com/watch?v=4NHQdHro5JA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>How to buy</Trans>
          </ExternalLink>
          <ExternalLink
            href={`https://space-dex.io/documents/whitepaper/space_dex_whitepaper_${currentLanguage}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Whitepaper
          </ExternalLink>
          <ExternalLink
            href={`https://space-dex.io/documents/onepage/${currentLanguage}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            One Page
          </ExternalLink>
          {!isMobile && (
            <>
              <ExternalLink
                href={`https://space-dex.io/documents/deck/space_dex_deck_${currentLanguage}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Deck
              </ExternalLink> 
              <ExternalLink
                href="https://space-dex.io/documents/guide/space_dex_guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Guide</Trans>
              </ExternalLink>
              <ExternalLink
                href="https://github.com/spcdex-io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </ExternalLink>
              <ExternalLink
                href="https://linktr.ee/spcdex"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linktree
              </ExternalLink>
              
            </>
          )}
        </Row>
        {!isMobile && (
          <img src={isDark ? Logo : LogoDark} alt="spacedex-logo" width={64} />
        )}
      </RowBetween>
      {isMobile && (
        <>
          <RowBetween>
            <ExternalLink
              href={`https://space-dex.io/documents/deck/space_dex_deck_${currentLanguage}.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Deck
            </ExternalLink> 
            <ExternalLink
              href="https://space-dex.io/documents/guide/space_dex_guide.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Guide</Trans>
            </ExternalLink>
            <ExternalLink
              href="https://github.com/spcdex-io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </ExternalLink>
            <ExternalLink
              href="https://linktr.ee/spcdex"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linktree
            </ExternalLink>
          </RowBetween>
          <RowCenter marginY="1rem">
            <img
              src={isDark ? Logo : LogoDark}
              alt="spacedex-logo"
              width={64}
            />
          </RowCenter>
        </>
      )}
      <Row>
        <ThemedText.Body>© {new Date().getFullYear()} SpaceDex</ThemedText.Body>
      </Row>
    </FooterFrame>
  );
}
