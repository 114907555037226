import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { ButtonEmpty } from "components/Button";
import { AutoColumn } from "components/Column";
import { useState } from "react";
import { useDarkModeManager } from "state/user/hooks";
import styled from "styled-components/macro";
import {
  faMessage,
  faNewspaper,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import InstagramLogo from "assets/images/socialnetworks/instagram-logo.png";
import MediumLogo from "assets/images/socialnetworks/medium-logo.png";
import TwitterLogo from "assets/images/socialnetworks/twitter-logo.png";
import YoutubeLogo from "assets/images/socialnetworks/youtube-logo.png";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Column = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.blue2};
  border-radius: 50%;
`;

const ButtonDropdown = styled(ButtonEmpty)`
  width: fit-content;
  display: flex;
  flex-direction: row;
  color: #fff;
  background: transparent;
`;

const FlyoutMenu = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  z-index: 100;
  padding: 12px;
  gap: 0.5rem;
`;

const ItemDropdown = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
    background: ${({ theme }) => theme.bg3};
  }
`;

const ExternalLink = styled.a`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.blue2};
  border-radius: 50%;
  color: ${({ theme }) => theme.text2};
`;

export function Community() {
  const [darkMode] = useDarkModeManager();

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  return (
    <Row>
      <ExternalLink
        href="https://twitter.com/spacedexF"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={TwitterLogo} alt="twitter-logo" width={24} />
      </ExternalLink>
      <ExternalLink
        href="https://www.instagram.com/spacedex.io/?r=nametag"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={InstagramLogo} alt="instragram-logo" width={24} />
      </ExternalLink>
      <ExternalLink
        href="https://www.youtube.com/channel/UCKy9Q4OxkApjZAdVXg_-D_g"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={YoutubeLogo} alt="youtube-logo" width={24} />
      </ExternalLink>
      <Column>
        <ButtonDropdown onClick={handleOpenDropdown}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </ButtonDropdown>
        <AutoColumn justify="center">
          {openDropdown && (
            <FlyoutMenu
              onMouseLeave={() => setOpenDropdown(false)}
              onClick={handleOpenDropdown}
            >
              <AutoColumn>
                <ItemDropdown
                  href="https://t.me/newspacedex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>News</Trans>
                  </ItemDropdown>
                  <FontAwesomeIcon icon={faNewspaper} />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEXofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Official</Trans>
                  </ItemDropdown>
                  <FontAwesomeIcon icon={faMessage} />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDexPortal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>English</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/england.png`}
                    width={16}
                    alt="english-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_ESP_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Spanish</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/spain.png`}
                    width={16}
                    alt="spanish-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_CN"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Chinese</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/china.png`}
                    width={16}
                    alt="china-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_PT_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Portuguese</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/portugal.png`}
                    width={16}
                    alt="portugal-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_VN_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Vietnam</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/vietnam.png`}
                    width={16}
                    alt="vietnam-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_ID_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Indonesia</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/indonesia.png`}
                    width={16}
                    alt="indonesia-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/SpaceDEX_TR_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Turkey</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/turkey.png`}
                    width={16}
                    alt="turkey-flag"
                  />
                </ItemDropdown>
                <ItemDropdown
                  href="https://t.me/spaceDEX_RUS_Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ItemDropdown color={darkMode ? "#C4D9F8" : "#4D8FEA"}>
                    <Trans>Russia</Trans>
                  </ItemDropdown>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/flags/russia.png`}
                    width={16}
                    alt="russia-flag"
                  />
                </ItemDropdown>
              </AutoColumn>
            </FlyoutMenu>
          )}
        </AutoColumn>
      </Column>
      <ExternalLink
        href="https://medium.com/@spacedexfalcon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={MediumLogo} alt="medium-logo" width={24} />
      </ExternalLink>
    </Row>
  );
}
