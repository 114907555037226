import { Trans } from "@lingui/macro";
import { useDarkModeManager } from "../../state/user/hooks";
import styled from "styled-components/macro";
import { ThemedText } from "../../theme";
import { AutoColumn } from "../../components/Column";
import Logo from "assets/images/spacedex-logo.png";
import LogoDark from "assets/images/spacedex-dark-logo.png";
import { isMobile } from "utils/userAgent";

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export function Privacy() {
  const [isDark] = useDarkModeManager();
  return (
    <PageWrapper>
      <Header>
        <img
          src={isDark ? Logo : LogoDark}
          alt="spacedex-logo"
          width={isMobile ? 300 : 500}
        />
      </Header>
      <AutoColumn gap="1rem">
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
          mt="1rem"
        >
          <Trans>Privacy</Trans>
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            This Privacy Policy document contains types of information that is
            collected and recorded by space-dex.io and how we use it. If you
            have additional questions or require more information about our
            Privacy Policy, do not hesitate to contact us. By using our website,
            you hereby consent to our Privacy Policy and agree to its terms
          </Trans>
          .
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Information</Trans>
        </ThemedText.LargeHeader>
        <AutoColumn gap="1rem">
          <Row>
            <ThemedText.Body textAlign="justify">
              <Trans>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal information
              </Trans>
              .
            </ThemedText.Body>
          </Row>
          <Row>
            <ThemedText.Body textAlign="justify">
              <Trans>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide
              </Trans>
              .
            </ThemedText.Body>
          </Row>
          <Row>
            <ThemedText.Body textAlign="justify">
              <Trans>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number
              </Trans>
              .
            </ThemedText.Body>
          </Row>
        </AutoColumn>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>How we use your information</Trans>
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            We use the information we collect in various ways, including to
          </Trans>
          :
        </ThemedText.Body>
        <ThemedText.Body textAlign="justify">
          <ul>
            <li>
              <Trans>Provide, operate, and maintain our website</Trans>
            </li>
            <li>
              <Trans>Improve, personalize, and expand our website</Trans>
            </li>
            <li>
              <Trans>Understand and analyze how you use our website</Trans>
            </li>
            <li>
              <Trans>
                Develop new products, services, features, and functionality
              </Trans>
            </li>
            <li>
              <Trans>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </Trans>
            </li>
            <li>
              <Trans>Send you emails</Trans>
            </li>
            <li>
              <Trans>Find and prevent fraud</Trans>
            </li>
          </ul>
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Log Files</Trans>
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            space-dex.io follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services’ analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users’ movement on the website, and gathering
            demographic information
          </Trans>
          .
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Cookies and Web Beacons</Trans>
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            Like any other website, space-dex.io uses ‘cookies’. These cookies
            are used to store information including visitors’ preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users’ experience by customizing
            our web page content based on visitors’ browser type and/or other
            information
          </Trans>
          .
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          Google DoubleClick DART Cookie
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            Google is one of a third-party vendor on our site. It also uses
            cookies, known as DART cookies, to serve ads to our site visitors
            based upon their visit to www.website.com and other sites on the
            internet
          </Trans>
          .
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Advertising Partners</Trans>
        </ThemedText.LargeHeader>
        <ThemedText.Body textAlign="justify">
          <Trans>
            Some of advertisers on our site may use cookies and web beacons. Our
            advertising partners are listed below. Each of our advertising
            partners has their own Privacy Policy for their policies on user
            data. For easier access, we hyperlinked to their Privacy Policies
            below
          </Trans>
          .
        </ThemedText.Body>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Advertising Partners Privacy Policies</Trans>
        </ThemedText.LargeHeader>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of space-dex.io
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on space-dex.io,
              which are sent directly to users’ browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Note that space-dex.io has no access to or control over these
              cookies that are used by third-party advertisers
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Third Party Privacy Policies</Trans>
        </ThemedText.LargeHeader>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              space-dex.io Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers
              respective websites
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </Trans>
        </ThemedText.LargeHeader>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Under the CCPA, among other rights, California consumers have the
              right to
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Request that a business that collects a consumer’s personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Request that a business delete any personal data about the
              consumer that a business has collected
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Request that a business that sells a consumer’s personal data, not
              sell the consumer’s personal data
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us
            </Trans>
            .
          </ThemedText.Body>
        </Row>

        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>GDPR Data Protection Rights</Trans>
        </ThemedText.LargeHeader>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is enLargeHeaderd to the following
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions
            </Trans>
            .
          </ThemedText.Body>
        </Row>

        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <ThemedText.LargeHeader
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          <Trans>Children’s Information</Trans>
        </ThemedText.LargeHeader>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity
            </Trans>
            .
          </ThemedText.Body>
        </Row>
        <Row>
          <ThemedText.Body textAlign="justify">
            <Trans>
              space-dex.io does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records
            </Trans>
            .
          </ThemedText.Body>
        </Row>
      </AutoColumn>
    </PageWrapper>
  );
}
