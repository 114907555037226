import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import { RowCenter } from "components/Row";
import { ButtonExternalLinkPrimary } from "components/Link";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 5rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const Section = styled.div`
    border-left: 5px solid ${({ theme }) => theme.primary1};
    padding: 8px;
`;

export function Spacetools() {
  return (
    <Container id={"spacetools"}>
      <Slide left>
        <ThemedText.Title
          color="primary5"
          textAlign={isMobile ? "center" : "left"}
        >
          SpaceTools
        </ThemedText.Title>
      </Slide>
      <Row>
        <Slide right>
          <AutoColumn gap="1rem">
            <Section>
              <ThemedText.Body>
                  <Trans>
                  In Spacetools, users can find all the information and operate their favorite tokens from the Bsc, Polygon and
                  Arbitrum networks through our interface
                  </Trans>
                  .
              </ThemedText.Body>
            </Section>
          </AutoColumn>
        </Slide>
      </Row>
      <RowCenter mt="2rem">
        <ButtonExternalLinkPrimary href="https://space-tools.io/" width="fit-content">
          Space Tools
        </ButtonExternalLinkPrimary>
      </RowCenter>
    </Container>
  );
}
