import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import Astronaut from "assets/images/home/astronaut2.png";
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 5rem;
  `};
`;

const Text = styled(ThemedText.Body)`
  span {
    color: ${({ theme }) => theme.primary5};
  }
`;

export function Mission() {
  return (
    <Container id={"mission"}>
      <Slide left>
        <AutoColumn gap="1rem">
          <ThemedText.Title
            color="primary5"
            textAlign={isMobile ? "center" : "left"}
          >
            <Trans>Mission and Vision</Trans>
          </ThemedText.Title>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Since its creation, our mission has been to make investing in
              Decentralized Finance accessible and beneficial to all
            </Trans>
            .
          </ThemedText.Body>
          <ThemedText.Body textAlign="justify">
            <Trans>
              We want SpaceDex to be the destination of choice for those seeking
              peace of mind when investing and earning rewards
            </Trans>
            .
          </ThemedText.Body>
          <ThemedText.Body textAlign="justify">
            <Trans>
              We aim to provide all the features of centralized exchanges with a
              more user-friendly interface, tools, accessibility, and added
              privacy. Users will be able to use SpaceDex while maintaining
              privacy in their transactions and control over their funds
            </Trans>
            .
          </ThemedText.Body>
          <Text fontSize={18} textAlign="justify">
            <Trans>Our goal is to be among the</Trans> <span>top 100</span>{" "}
            <Trans>exchanges in terms of volume within the next</Trans>{" "}
            <span>
              12 <Trans>months</Trans>
            </span>
            .
          </Text>
        </AutoColumn>
      </Slide>
      <Slide right>
        <AutoColumn>
          <img
            src={Astronaut}
            alt="astronaut-img"
            width={isMobile ? 256 : 350}
          />
        </AutoColumn>
      </Slide>
    </Container>
  );
}
