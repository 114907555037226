import { Trans } from "@lingui/macro";
import Slide, { Roll } from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import FalconBlack from "assets/images/falcon-black-logo.png";
import FalconWhite from "assets/images/falcon-white-logo.png";
import FLPWhite from "assets/images/flp-white-logo.png";
import FLPBlack from "assets/images/flp-black-logo.png";
import { isMobile } from "utils/userAgent";
import { useDarkModeManager } from "state/user/hooks";
import { AutoRow } from "components/Row";
import { ButtonExternalLinkPrimary } from "components/Link";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 5rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const SectionLeft = styled.div`
  border-left: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const SectionRight = styled.div`
  border-right: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

export function Ecosystem() {
  const [isDark] = useDarkModeManager();
  return (
    <Container id={"ecosystem"}>
      <ThemedText.Title
        color="primary5"
        textAlign={isMobile ? "center" : "left"}
      >
        <Trans>Ecosystem</Trans>
      </ThemedText.Title>
      <Row>
        <Slide left>
          <AutoColumn gap="1rem">
            <ThemedText.MediumHeader color="yellow3">
              <Trans>FALCON (FLN)</Trans>
            </ThemedText.MediumHeader>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  FLN is the governance token of the platform, used to earn
                  returns from staking 25% of the platform's fees plus 10%-20%
                  of profits from funded accounts
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  Falcon is a utility token for the platform's services. To use
                  copytrading, you must have 200,000 FLN staked. Additionally,
                  new services for our governance token will be implemented in
                  the future
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  Additionally, there are 5x ASIC L7 ANTMINER machines that
                  distribute their yield to the FLN stake on ARBITRUM
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
          </AutoColumn>
        </Slide>
        <Roll right>
          <AutoColumn>
            <img
              src={isDark ? FalconWhite : FalconBlack}
              alt="fln-logo"
              width={220}
            />
          </AutoColumn>
        </Roll>
      </Row>
      <AutoRow gap="0.5rem" justify="center">
        <ButtonExternalLinkPrimary
          href="https://pancakeswap.finance/swap?outputCurrency=0x37D39950f9C753d62529DbF68fCb4DCa4004fBFd"
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Buy on</Trans> BSC
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
        <ButtonExternalLinkPrimary
          href="https://www.sushi.com/swap?fromChainId=42161&fromCurrency=0xA822CfD3AcbC0eB1a1Aba073B3355aCaF756ef7F&toChainId=42161&toCurrency=NATIVE&amount="
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Buy on</Trans> ARB
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
      </AutoRow>
      <Row>
        {!isMobile && (
          <Roll left>
            <AutoColumn>
              <img
                src={isDark ? FLPWhite : FLPBlack}
                alt="flp-logo"
                width={220}
              />
            </AutoColumn>
          </Roll>
        )}
        <Slide Right>
          <AutoColumn gap="1rem">
            <ThemedText.MediumHeader textAlign="right" color="yellow3">
              <Trans>FALCON LP (FLP)</Trans>
            </ThemedText.MediumHeader>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  FLP is a low-risk, high-yield liquidity token, whose price is
                  based on liquid tokens. You don't need trading knowledge to
                  obtain attractive returns because the process has been
                  simplified to give access to everyone
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  FLP generates automatic rewards that can be withdrawn without
                  penalty or time restrictions. Furthermore, you have total
                  control of your investments with just one click
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  The liquid tokens used for each network on the platform are as
                  follows
                </Trans>
                :
                <br />
                Binance Smart Chain {"->"} BTC, ETH, BNB, and USDT
                <br />
                Arbitrum One {"->"} BTC, ETH, LINK, and USDT
              </ThemedText.Body>
            </SectionRight>
          </AutoColumn>
        </Slide>
        {isMobile && (
          <Roll left>
            <AutoColumn>
              <img
                src={isDark ? FLPWhite : FLPBlack}
                alt="flp-logo"
                width={220}
              />
            </AutoColumn>
          </Roll>
        )}
      </Row>
      <AutoRow gap="0.5rem" justify="center">
        <ButtonExternalLinkPrimary
          href="https://app.space-dex.io/buy?chain=binance"
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Buy on</Trans> BSC
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
        <ButtonExternalLinkPrimary
          href="https://app.space-dex.io/buy?chain=arbitrum_one"
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Buy on</Trans> ARB
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
      </AutoRow>
    </Container>
  );
}
