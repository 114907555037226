import { useState } from "react";
import styled, { keyframes } from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.button<{
  activeElement?: boolean;
}>`
  align-items: center;
  background: ${({ theme }) => theme.primary1};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0.4rem 0.4rem;
  width: fit-content;
  height: 3em;
`;

const turnOnToggle = keyframes`
  from {
    margin-left: 0em;
    margin-right: 2.2em;
  }
  to {
    margin-left: 2.2em;
    margin-right: 0em;
  }
`;

const turnOffToggle = keyframes`
  from {
    margin-left: 2.2em;
    margin-right: 0em;
  }
  to {
    margin-left: 0em;
    margin-right: 2.2em;
  }
`;

const ToggleElement = styled.span<{
  isActive?: boolean;
  bgColor?: string;
  isInitialToggleLoad?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: 0.1s
    ${({ isActive, isInitialToggleLoad }) =>
      isInitialToggleLoad ? "none" : isActive ? turnOnToggle : turnOffToggle}
    ease-in;
  background: ${({ theme }) => theme.blue1};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-left: ${({ isActive }) => (isActive ? "2.2em" : "0em")};
  margin-right: ${({ isActive }) => (!isActive ? "2.2em" : "0em")};
`;

interface ColorToggleProps {
  id?: string;
  bgColor?: string;
  isActive: boolean;
  toggle: () => void;
}

export default function ColorToggle({
  id,
  bgColor,
  isActive,
  toggle,
}: ColorToggleProps) {
  const [isInitialToggleLoad, setIsInitialToggleLoad] = useState(true);

  const switchToggle = () => {
    toggle();
    if (isInitialToggleLoad) setIsInitialToggleLoad(false);
  };

  return (
    <Wrapper id={id} onClick={switchToggle}>
      <ToggleElement
        isActive={isActive}
        bgColor={bgColor}
        isInitialToggleLoad={isInitialToggleLoad}
      >
        {isActive ? (
          <FontAwesomeIcon icon={faMoon} color="#fff" />
        ) : (
          <FontAwesomeIcon icon={faSun} color="#000" />
        )}
      </ToggleElement>
    </Wrapper>
  );
}
