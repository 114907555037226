import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import ReferralImg from "assets/images/home/referral.png";
import ReferralImg2 from "assets/images/home/referral2.png";
import { ButtonExternalLinkPrimary } from "components/Link";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const ResponsiveImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    justify-content: space-between;
  `};
`;

const ResponsiveFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: space-between;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: space-between;
  `};
`;

export function Referral() {
  return (
    <Container id={"referral"}>
      <ThemedText.Title
        color="primary5"
        textAlign={isMobile ? "center" : "left"}
      >
        <Trans>Referral</Trans>
      </ThemedText.Title>
      <Slide Left>
        <AutoColumn gap="1rem">
          <ThemedText.Body textAlign="justify">
            <Trans>
              Increase your passive income through the referral system. Invite
              your friends to trade on SpaceDex and they'll get commission
              discounts, while you'll earn a percentage of their trades
            </Trans>
            .
          </ThemedText.Body>
        </AutoColumn>
      </Slide>
      <Slide Right>
        <ResponsiveImg>
          <img
            src={ReferralImg2}
            alt="referral-img"
            width={isMobile ? 150 : 300}
          />
          <img
            src={ReferralImg}
            alt="referral-img"
            width={isMobile ? 150 : 300}
          />
        </ResponsiveImg>
      </Slide>
      <ResponsiveFlex>
        <ButtonExternalLinkPrimary
          href="https://app.space-dex.io/referrals?chain=binance"
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Create on</Trans> BSC
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
        <ButtonExternalLinkPrimary
          href="https://app.space-dex.io/referrals?chain=arbitrum_one"
          target="_blank"
          rel="noopener noreferrer"
          width="fit-content"
        >
          <ThemedText.Body fontSize={isMobile ? 14 : 16}>
            <Trans>Create on</Trans> ARB
          </ThemedText.Body>
        </ButtonExternalLinkPrimary>
      </ResponsiveFlex>
    </Container>
  );
}
