import ParticlesDarkOptions from "components/Particles/stars_dark.json";
import ParticlesLightOptions from "components/Particles/stars_light.json";
import { useCallback } from "react";
import Particles from "react-particles";
import type { Container, Engine, ISourceOptions } from "tsparticles-engine";
import { loadFull } from "tsparticles";

import { useDarkModeManager } from "state/user/hooks";

export default function ParticlesBackground() {
  const [isDark] = useDarkModeManager();
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container);
    },
    []
  );
  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={
        (isDark
          ? ParticlesDarkOptions
          : ParticlesLightOptions) as ISourceOptions
      }
    />
  );
}
