import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useDarkModeManager } from "state/user/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

export function Roadmap() {
  const [isDark] = useDarkModeManager();

  const currentMonth = new Date().getMonth();
  let currentQuarter = 1;

  switch (currentMonth) {
    case 0:
    case 1:
    case 2:
      currentQuarter = 1;
      break;
    case 3:
    case 4:
    case 5:
      currentQuarter = 2;
      break;
    case 6:
    case 7:
    case 8:
      currentQuarter = 3;
      break;
    case 9:
    case 10:
    case 11:
      currentQuarter = 4;
      break;
    default:
      break;
  }

  const lineBlue = isDark ? "#2172E5" : "#93BBF2";
  const lightGrey = "#9b9b9b";
  const darkGrey = "#383b3d";
  const primaryBlue = isDark ? "#124896" : "#93bbf3";
  const activeYellow = "#f3b71e";
  const iconColor = isDark ? "#fff" : "#486CCC";

  return (
    <Container id={"roadmap"}>
      <Slide top>
        <AutoColumn gap="1rem">
          <ThemedText.Title
            color="primary5"
            textAlign={isMobile ? "center" : "left"}
          >
            <Trans>RoadMap</Trans>
          </ThemedText.Title>
          <VerticalTimeline lineColor={lineBlue}>
            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{
                background: primaryBlue,
              }}
              contentArrowStyle={{
                borderRight: `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>Q4 2022</ThemedText.Body>}
              iconStyle={{
                background: lightGrey,
                color: darkGrey,
                boxShadow: `0 0 0 4px ${darkGrey}`,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>Social media launch and marketing</Trans>.
                  </li>
                  <li>
                    <Trans>Presale & listing Pancakeswap</Trans>.
                  </li>
                  <li>
                    <Trans>Launch of Testnet app</Trans>.
                  </li>
                  <li>
                    <Trans>Staking</Trans>.
                  </li>
                  <li>
                    <Trans>Audit Certik</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: primaryBlue,
                border:
                  currentQuarter === 1 ? `1px solid ${activeYellow}` : "none",
                boxShadow:
                  currentQuarter === 1
                    ? `0 3px 0 ${activeYellow}`
                    : "0 3px 0 #ddd",
              }}
              contentArrowStyle={{
                borderRight:
                  currentQuarter === 1
                    ? `7px solid  ${activeYellow}`
                    : `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>Q1 2023</ThemedText.Body>}
              iconStyle={{
                background: currentQuarter === 1 ? primaryBlue : lightGrey,
                color: currentQuarter === 1 ? activeYellow : darkGrey,
                boxShadow:
                  currentQuarter === 1
                    ? `0 0 0 4px ${activeYellow}`
                    : `0 0 0 4px ${darkGrey}`,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>End of Certik's audit</Trans>.
                  </li>
                  <li>
                    <Trans>Launch of Mainnet app</Trans>.
                  </li>
                  <li>
                    <Trans>Launch SpaceTools</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: primaryBlue,
                border:
                  currentQuarter === 2 ? `1px solid ${activeYellow}` : "none",
                boxShadow:
                  currentQuarter === 2
                    ? `0 3px 0 ${activeYellow}`
                    : "0 3px 0 #ddd",
              }}
              contentArrowStyle={{
                borderRight:
                  currentQuarter === 2
                    ? `7px solid  ${activeYellow}`
                    : `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>Q2 2023</ThemedText.Body>}
              iconStyle={{
                background: currentQuarter <= 2 ? primaryBlue : lightGrey,
                color:
                  currentQuarter <= 2
                    ? currentQuarter === 2
                      ? activeYellow
                      : iconColor
                    : darkGrey,
                boxShadow:
                  currentQuarter <= 2
                    ? currentQuarter === 2
                      ? `0 0 0 4px ${activeYellow}`
                      : `0 0 0 4px ${iconColor}`
                    : `0 0 0 4px ${darkGrey}`,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>Trade panel update</Trans>.
                  </li>
                  <li>
                    <Trans>Added Trading View</Trans>.
                  </li>
                  <li>
                    <Trans>Development and auditing of copy trading</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: primaryBlue,
                border:
                  currentQuarter === 3 ? `1px solid ${activeYellow}` : "none",
                boxShadow:
                  currentQuarter === 3
                    ? `0 3px 0 ${activeYellow}`
                    : "0 3px 0 #ddd",
              }}
              contentArrowStyle={{
                borderRight:
                  currentQuarter === 3
                    ? `7px solid  ${activeYellow}`
                    : `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>Q3 2023</ThemedText.Body>}
              iconStyle={{
                background: currentQuarter <= 3 ? primaryBlue : lightGrey,
                color:
                  currentQuarter <= 3
                    ? currentQuarter === 3
                      ? activeYellow
                      : iconColor
                    : darkGrey,
                boxShadow:
                  currentQuarter <= 3
                    ? currentQuarter === 3
                      ? `0 0 0 4px ${activeYellow}`
                      : `0 0 0 4px ${iconColor}`
                    : `0 0 0 4px ${darkGrey}`,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>End of copy trading audit</Trans>.
                  </li>
                  <li>
                    <Trans>Copy trading</Trans>.
                  </li>
                  <li>
                    <Trans>Expansion SpaceDex to other networks</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: primaryBlue,
                border:
                  currentQuarter === 4 ? `1px solid ${activeYellow}` : "none",
                boxShadow:
                  currentQuarter === 4
                    ? `0 3px 0 ${activeYellow}`
                    : "0 3px 0 #ddd",
              }}
              contentArrowStyle={{
                borderRight:
                  currentQuarter === 4
                    ? `7px solid  ${activeYellow}`
                    : `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>Q4 2023</ThemedText.Body>}
              iconStyle={{
                background: currentQuarter <= 4 ? primaryBlue : lightGrey,
                color:
                  currentQuarter <= 4
                    ? currentQuarter === 4
                      ? activeYellow
                      : iconColor
                    : darkGrey,
                boxShadow:
                  currentQuarter <= 4
                    ? currentQuarter === 4
                      ? `0 0 0 4px ${activeYellow}`
                      : `0 0 0 4px ${iconColor}`
                    : `0 0 0 4px ${darkGrey}`,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>Trading bots</Trans>.
                  </li>
                  <li>
                    <Trans>Lending</Trans>.
                  </li>
                  <li>
                    <Trans>Development of more functions for SpaceTools</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: primaryBlue,
                boxShadow: "0 3px 0 #ddd",
              }}
              contentArrowStyle={{
                borderRight: `7px solid ${primaryBlue}`,
              }}
              date={<ThemedText.Body>2024</ThemedText.Body>}
              iconStyle={{
                background: primaryBlue,
                color: iconColor,
                boxShadow: iconColor,
              }}
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              <ThemedText.Body>
                <ul>
                  <li>
                    <Trans>SpaceDex wallet app</Trans>.
                  </li>
                </ul>
              </ThemedText.Body>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </AutoColumn>
      </Slide>
    </Container>
  );
}
