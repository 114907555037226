import { Trans } from "@lingui/macro";
import Slide, { Flip } from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import SocialNetworks from "assets/images/home/social-networks.png";
import { RowCenter } from "components/Row";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const Section = styled.div`
  background: ${({ theme }) => theme.primary1};
  padding: 16px;
  border-radius: 8px;
`;

export function Social() {
  return (
    <Container id={"social"}>
      <Slide top>
        <AutoColumn gap="1rem">
          <ThemedText.Title
            color="primary5"
            textAlign={isMobile ? "center" : "left"}
          >
            <Trans>Social</Trans>
          </ThemedText.Title>
          <ThemedText.Body textAlign="justify">
            <Trans>
              Our platform allows users to copy-trade their favorite coins
              without leaving the exchange. Users can share their trades
              directly with each other through social media channels such as
              Twitter, Instagram, and Facebook
            </Trans>
            .
          </ThemedText.Body>
        </AutoColumn>
      </Slide>
      <Flip bottom>
        <RowCenter>
          <img
            src={SocialNetworks}
            alt="social-img"
            width={isMobile ? 256 : 512}
          />
        </RowCenter>
      </Flip>
      <Slide bottom>
        <Section>
          <ThemedText.Body textAlign="center">
            <Trans>
              Our platform will also offer contracts with performance
              verification for traders, allowing non-experts to see the trades
              of experienced traders and copy them if desired
            </Trans>
            .
          </ThemedText.Body>
        </Section>
      </Slide>
    </Container>
  );
}
