import { Trans } from "@lingui/macro";
import { AutoColumn } from "components/Column";
import Slide from "react-reveal";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import Greenblock from "assets/images/partneship/greenblock.png";

import { isMobile } from "utils/userAgent";
import { ButtonExternalLinkPrimary } from "components/Link";
import { RowCenter } from "components/Row";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export function Partnership() {
  return (
    <Container id={"partnership"}>
      <ThemedText.Title
        color="primary5"
        textAlign={isMobile ? "center" : "left"}
      >
        <Trans>Partnerships</Trans>
      </ThemedText.Title>
      <Slide bottom>
        <AutoColumn gap="0.5rem">
          <Row>
            <img src={Greenblock} alt="advisor-img" width={256} />
          </Row>
          <ThemedText.MediumHeader textAlign="center">
            Green Block Capital
          </ThemedText.MediumHeader>
          <ThemedText.Main textAlign="center">Partnership</ThemedText.Main>
          <RowCenter>
            <ButtonExternalLinkPrimary
              width="fit-content"
              href="https://greenblockcapital.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web
            </ButtonExternalLinkPrimary>
          </RowCenter>
        </AutoColumn>
      </Slide>
    </Container>
  );
}
