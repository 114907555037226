import { Trans } from "@lingui/macro";
import Slide, { Roll } from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import EcosystemDark from "assets/images/home/ecosystem-dark.png";
import EcosystemLight from "assets/images/home/ecosystem-light.png";
import Rewards from "assets/images/home/rewards.png";
import { isMobile } from "utils/userAgent";
import { useDarkModeManager } from "state/user/hooks";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 5rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const Section = styled.div`
  background: ${({ theme }) => theme.primary1};
  padding: 16px;
  border-radius: 8px;
`;

const SectionLeft = styled.div`
  border-left: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const SectionRight = styled.div`
  border-right: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

export function Liquidity() {
  const [isDark] = useDarkModeManager();
  return (
    <Container id={"liquidity"}>
      <Row>
        <Slide left>
          <AutoColumn gap="1rem">
            <ThemedText.Title
              color="primary5"
              textAlign={isMobile ? "center" : "left"}
            >
              <Trans>Liquidity</Trans>
            </ThemedText.Title>
            <ThemedText.MediumHeader color="yellow3">
              <Trans>Add</Trans>
            </ThemedText.MediumHeader>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  With the potential to earn $1 Falcon LP for each dollar
                  deposited in any supported token on our liquidity vault, the
                  liquidity fees depend on the vault's liquidity and can reach a
                  maximum of 0.25%
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
            <SectionLeft>
              <ThemedText.Body textAlign="justify">
                <Trans>
                  In short, if the vault holds less BNB than BTC, depositing BNB
                  will result in a 0% fee and withdrawing BNB will incur a fee
                  of up to 0.25%
                </Trans>
                .
              </ThemedText.Body>
            </SectionLeft>
          </AutoColumn>
        </Slide>
        <Roll right>
          <AutoColumn>
            <img
              src={isDark ? EcosystemLight : EcosystemDark}
              alt="ecosystem-img"
              width={256}
            />
          </AutoColumn>
        </Roll>
      </Row>
      <Row>
        {!isMobile && (
          <Roll left>
            <AutoColumn>
              <img src={Rewards} alt="rewards-img" width={256} />
            </AutoColumn>
          </Roll>
        )}
        <Slide Right>
          <AutoColumn gap="1rem">
            <ThemedText.MediumHeader textAlign="right" color="yellow3">
              <Trans>Rewards</Trans>
            </ThemedText.MediumHeader>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  70% of all fees from swap trading and leveraging are converted
                  to BNB and distributed among the staked tokens
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
            <SectionRight>
              <ThemedText.Body textAlign="right">
                <Trans>
                  The distribution of BNB takes into account Referral Rewards
                  and the network costs of Keepers, which typically account for
                  1% of the total fees
                </Trans>
                .
              </ThemedText.Body>
            </SectionRight>
          </AutoColumn>
        </Slide>
        {isMobile && (
          <Roll left>
            <AutoColumn>
              <img src={Rewards} alt="rewards-img" width={220} />
            </AutoColumn>
          </Roll>
        )}
      </Row>
      <Row>
        <Slide bottom>
          <AutoColumn gap="1rem">
            <Section>
              <ThemedText.Body textAlign="center">
                <Trans>
                  5% of all fees generated from swapping and leveraging will be
                  used to buy Falcon LP tokens on the market and burned
                  permanently. The calculation of distributed BNB also takes
                  into account Referral Rewards and the network costs of Keepers
                </Trans>
                .
              </ThemedText.Body>
            </Section>
          </AutoColumn>
        </Slide>
      </Row>
    </Container>
  );
}
