import { Trans } from "@lingui/macro";
import { useDarkModeManager } from "../../state/user/hooks";
import styled from "styled-components/macro";
import { ThemedText } from "../../theme";
import { AutoColumn } from "../../components/Column";
import Logo from "assets/images/spacedex-logo.png";
import LogoDark from "assets/images/spacedex-dark-logo.png";
import { ButtonExternalLinkPrimary } from "components/Link";
import { Community } from "./components/Community";
import { Mission } from "./components/Mission";
import { isMobile } from "utils/userAgent";
import { Tokenomics } from "./components/Tokenomics";
/* import { Staking } from "./components/Staking"; */
import { Trading } from "./components/Trading";
import { Social } from "./components/Social";
import { Referral } from "./components/Referral";
import { Liquidity } from "./components/Liquidity";
import { Roadmap } from "./components/Roadmap";
/* import { Team } from "./components/Team"; */
import { Spacetools } from "./components/Spacetools";
import { Partnership } from "./components/Partnership";
import { AutoRow, RowCenter } from "components/Row";
import { Copytrading } from "./components/CopyTrading";

import FalconBlack from "assets/images/falcon-black-logo.png";
import FalconWhite from "assets/images/falcon-white-logo.png";
import FLPWhite from "assets/images/flp-white-logo.png";
import FLPBlack from "assets/images/flp-black-logo.png";
import Ship from "assets/images/home/ship.png";
import { Ecosystem } from "./components/Ecosystem";

const PageWrapper = styled(AutoColumn)`
  max-width: 1000px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;

const ResponsiveFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  `};
`;

const DarkCard = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg2};
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 16px;
  gap: 1rem;
`;

export function Home() {
  const [isDark] = useDarkModeManager();
  return (
    <PageWrapper>
      <RowCenter mb="1rem">
        <img
          src={isDark ? Logo : LogoDark}
          alt="spacedex-logo"
          width={isMobile ? 300 : 500}
        />
      </RowCenter>
      <ThemedText.Body textAlign="justify">
        <Trans>
          SpaceDex, the decentralized exchange platform designed for traders of
          all levels. No deposits, no registrations, no KYC, and no limitations
        </Trans>
        .
      </ThemedText.Body>
      <ResponsiveFlex>
        <DarkCard>
          <AutoRow gap="0.2rem">
            <img
              src={isDark ? FalconWhite : FalconBlack}
              alt="falcon-logo"
              width={40}
            />
            <ThemedText.LargeHeader color="text1">
              FALCON
            </ThemedText.LargeHeader>
          </AutoRow>
          <ThemedText.Body>
            <Trans>
              FLN is the utility and governance token. Accrues 25% of the fees
              generated by the platform with the stake
            </Trans>
            .
          </ThemedText.Body>
          <AutoRow gap={isMobile ? "0.2rem" : "0.5rem"} justify="center">
            <ButtonExternalLinkPrimary
              href="https://pancakeswap.finance/swap?outputCurrency=0x37D39950f9C753d62529DbF68fCb4DCa4004fBFd"
              target="_blank"
              rel="noopener noreferrer"
              width="fit-content"
            >
              <ThemedText.Body fontSize={isMobile ? 14 : 16}>
                <Trans>Buy on</Trans> BSC
              </ThemedText.Body>
            </ButtonExternalLinkPrimary>
            <ButtonExternalLinkPrimary
              href="https://www.sushi.com/swap?fromChainId=42161&fromCurrency=0xA822CfD3AcbC0eB1a1Aba073B3355aCaF756ef7F&toChainId=42161&toCurrency=NATIVE&amount="
              target="_blank"
              rel="noopener noreferrer"
              width="fit-content"
            >
              <ThemedText.Body fontSize={isMobile ? 14 : 16}>
                <Trans>Buy on</Trans> ARB
              </ThemedText.Body>
            </ButtonExternalLinkPrimary>
          </AutoRow>
        </DarkCard>
        <DarkCard>
          <AutoRow gap="0.2rem">
            <img
              src={isDark ? FLPWhite : FLPBlack}
              alt="falcon-logo"
              width={40}
            />
            <ThemedText.LargeHeader color="text1">
              FALCON LP
            </ThemedText.LargeHeader>
          </AutoRow>
          <ThemedText.Body>
            <Trans>
              FLP is the liquidity provider token. Get automatic rewards with
              low risk and easily withdraw them without penalties
            </Trans>
            .
          </ThemedText.Body>
          <AutoRow gap={isMobile ? "0.2rem" : "0.5rem"} justify="center">
            <ButtonExternalLinkPrimary
              href="https://app.space-dex.io/buy?chain=binance"
              target="_blank"
              rel="noopener noreferrer"
              width="fit-content"
            >
              <ThemedText.Body fontSize={isMobile ? 14 : 16}>
                <Trans>Buy on</Trans> BSC
              </ThemedText.Body>
            </ButtonExternalLinkPrimary>
            <ButtonExternalLinkPrimary
              href="https://app.space-dex.io/buy?chain=arbitrum_one"
              target="_blank"
              rel="noopener noreferrer"
              width="fit-content"
            >
              <ThemedText.Body fontSize={isMobile ? 14 : 16}>
                <Trans>Buy on</Trans> ARB
              </ThemedText.Body>
            </ButtonExternalLinkPrimary>
          </AutoRow>
        </DarkCard>
      </ResponsiveFlex>

      <RowCenter>
        <img src={Ship} alt="ship-img" width={isMobile ? 256 : 350} />
      </RowCenter>
      <Community />
      <Copytrading />
      <Ecosystem />
      <Referral />
      <Liquidity />
      <Mission />
      <Roadmap />
      <Tokenomics />
      {/* <Staking /> */}
      <Trading />
      <Social />
      <Spacetools />
      {/* <Team /> */}
      <Partnership />
    </PageWrapper>
  );
}
