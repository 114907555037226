import { Trans } from "@lingui/macro";
import Slide from "react-reveal";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { isMobile } from "utils/userAgent";
import { RowAround, RowCenter } from "components/Row";
import { ButtonExternalLinkPrimary } from "components/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { MouseoverTooltip } from "components/Tooltip";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  gap: 1rem;
`;

const ResponsiveFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const ArrowBg = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primary1};
  border-radius: 24px;
  padding: 12px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  transform: translate(640%, 0);
  ${({ theme }) => theme.mediaWidth.upToLarge`
    transform: translate(600%, 0);
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    transform: translate(0, 620%);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    transform: translate(0, 620%);
  `};
`;

const ArrowWrapper2 = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translate(1350%, 0);
  ${({ theme }) => theme.mediaWidth.upToLarge`
    transform: translate(1250%, 0);
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    transform: translate(0, 1310%);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    transform: translate(0, 1310%);
  `};
`;

const DarkCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg2};
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 16px;
`;

export function Copytrading() {
  return (
    <Container id={"copytrading"}>
      <ThemedText.Title
        color="primary5"
        textAlign={isMobile ? "center" : "left"}
      >
        Copy Trading
      </ThemedText.Title>
      <ThemedText.Body>
        <Trans>
          Access the copytrading service to become a trader and generate
          profits, or to copy the most successful traders. The service can be
          used when you have 200,000 FLN in stake
        </Trans>
        .
      </ThemedText.Body>
      <Slide left>
        <ThemedText.LargeHeader color="yellow3">
          <Trans>Profiles</Trans>
        </ThemedText.LargeHeader>
        <ResponsiveFlex>
          <DarkCard>
            <ThemedText.LargeHeader
              color="text1"
              textAlign={isMobile ? "center" : "left"}
              mb="1rem"
            >
              Trader
            </ThemedText.LargeHeader>
            <ThemedText.Body>
              <Trans>
                Create your trader profile and earn a percentage of up to 90% of
                the profit your followers make from your trades
              </Trans>
              .
            </ThemedText.Body>
          </DarkCard>
          <DarkCard>
            <ThemedText.LargeHeader
              color="text1"
              textAlign={isMobile ? "center" : "left"}
              mb="1rem"
            >
              <Trans>User</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.Body>
              <Trans>
                Trade effortlessly and access performance information of all
                traders to choose and copy the one you like the most
              </Trans>
              .
            </ThemedText.Body>
          </DarkCard>
        </ResponsiveFlex>
      </Slide>
      <Slide right>
        <ThemedText.LargeHeader color="yellow3">
          <Trans>Funded Accounts</Trans>
        </ThemedText.LargeHeader>
        <ResponsiveFlex>
          <DarkCard style={{ minHeight: "315px" }}>
            <ThemedText.LargeHeader
              color="text1"
              textAlign={isMobile ? "center" : "center"}
              mb="1rem"
            >
              <Trans>Beginner Trader</Trans>
            </ThemedText.LargeHeader>
            <AutoColumn gap="0.5rem">
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Free initial real balance</Trans>
              </ThemedText.Body>
              <ThemedText.LargeHeader textAlign="center" color="yellow3">
                $50
              </ThemedText.LargeHeader>
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Requirement</Trans>
              </ThemedText.Body>
              <ThemedText.Body textAlign="center" color="primary5">
                <Trans>None</Trans>
              </ThemedText.Body>
              <ThemedText.Body mt="1.5rem" textAlign="center" color="text2">
                <Trans>Profit Sharing</Trans>
              </ThemedText.Body>
              <RowAround>
                <ThemedText.Body textAlign="center" color="success">
                  80% Trader
                </ThemedText.Body>
                <ThemedText.Body textAlign="center" color="success">
                  20% Stake FLN
                </ThemedText.Body>
              </RowAround>
            </AutoColumn>
          </DarkCard>

          <ArrowWrapper>
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>
                    Increase your tier monthly by achieving positive results to
                    earn up to $500 in your trading balance
                  </Trans>
                </ThemedText.SubHeader>
              }
            >
              <ArrowBg>
                <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} />
              </ArrowBg>
            </MouseoverTooltip>
          </ArrowWrapper>

          <DarkCard style={{ minHeight: "315px" }}>
            <ThemedText.LargeHeader
              color="text1"
              textAlign={isMobile ? "center" : "center"}
              mb="1rem"
            >
              <Trans>Expert Trader</Trans>
            </ThemedText.LargeHeader>
            <AutoColumn gap="0.5rem">
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Free initial real balance</Trans>
              </ThemedText.Body>
              <ThemedText.LargeHeader textAlign="center" color="yellow3">
                $500
              </ThemedText.LargeHeader>
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Requirement</Trans>
              </ThemedText.Body>
              <ThemedText.Body textAlign="center" color="primary5">
                <Trans>Trading Community</Trans>
              </ThemedText.Body>
              <ThemedText.Body mt="1.5rem" textAlign="center" color="text2">
                <Trans>Profit Sharing</Trans>
              </ThemedText.Body>
              <RowAround>
                <ThemedText.Body textAlign="center" color="success">
                  85% Trader
                </ThemedText.Body>
                <ThemedText.Body textAlign="center" color="success">
                  15% Stake FLN
                </ThemedText.Body>
              </RowAround>
            </AutoColumn>
          </DarkCard>
          <ArrowWrapper2>
            <MouseoverTooltip
              text={
                <ThemedText.SubHeader>
                  <Trans>
                    Increase your tier monthly by achieving positive results to
                    earn up to $1000 in your trading balance
                  </Trans>
                </ThemedText.SubHeader>
              }
            >
              <ArrowBg>
                <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} />
              </ArrowBg>
            </MouseoverTooltip>
          </ArrowWrapper2>
          <DarkCard style={{ minHeight: "315px" }}>
            <ThemedText.LargeHeader
              color="text1"
              textAlign={isMobile ? "center" : "center"}
              mb="1rem"
            >
              Trader VIP
            </ThemedText.LargeHeader>
            <AutoColumn gap="0.5rem">
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Free initial real balance</Trans>
              </ThemedText.Body>
              <ThemedText.LargeHeader textAlign="center" color="yellow3">
                $3,000
              </ThemedText.LargeHeader>
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Requirement</Trans>
              </ThemedText.Body>
              <ThemedText.Body textAlign="center" color="primary5">
                <Trans>Trading Community or Social Networks</Trans> {">"} 10,000{" "}
                <Trans>followers</Trans>
              </ThemedText.Body>
              <ThemedText.Body textAlign="center" color="text2">
                <Trans>Profit Sharing</Trans>
              </ThemedText.Body>
              <RowAround>
                <ThemedText.Body textAlign="center" color="success">
                  90% Trader
                </ThemedText.Body>
                <ThemedText.Body textAlign="center" color="success">
                  10% Stake FLN
                </ThemedText.Body>
              </RowAround>
            </AutoColumn>
          </DarkCard>
        </ResponsiveFlex>
        <RowCenter>
          <ButtonExternalLinkPrimary
            width="fit-content"
            href="https://forms.gle/7wsioaSoxqa664aJ6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ThemedText.Body fontSize={isMobile ? "14px" : "16px"}>
              <Trans>Access free funds for trade</Trans>
            </ThemedText.Body>
          </ButtonExternalLinkPrimary>
        </RowCenter>
      </Slide>
    </Container>
  );
}
